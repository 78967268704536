import { render, staticRenderFns } from "./MontrealMap.vue?vue&type=template&id=42b5a94b&scoped=true"
import script from "./MontrealMap.vue?vue&type=script&lang=js"
export * from "./MontrealMap.vue?vue&type=script&lang=js"
import style0 from "./MontrealMap.vue?vue&type=style&index=0&id=42b5a94b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b5a94b",
  null
  
)

export default component.exports