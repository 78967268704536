<template>
  <div class="montreal-map">
    <svg :viewBox="viewBox">
      <g>
        <path
          v-for="neighborhood in validNeighborhoods"
          :key="neighborhood.id"
          :d="pathForFeature(neighborhood)"
          :fill="getColor(neighborhood.name)"
          @mouseover="showTooltip(neighborhood)"
          @mouseout="hideTooltip"
        />
      </g>
      <g class="text-overlay">
        <text
          v-for="neighborhood in validNeighborhoods"
          :key="neighborhood.id + '-text'"
          :x="centroid(neighborhood)[0]"
          :y="centroid(neighborhood)[1]"
          font-size="8"
          font-weight="bold"
          text-anchor="middle"
          fill="black"
          style="pointer-events: none;"
        >
          {{ neighborhood.id }}
        </text>
      </g>
    </svg>

    <div v-if="activeNeighborhood" class="tooltip-panel">
      <p><strong>{{ activeNeighborhood.name }}</strong></p>
      <p>Rental   Median  Price : {{ formatPrice(activeNeighborhood.rentMedian) }} $</p>
      <p>Rental   Average Price : {{ formatPrice(activeNeighborhood.rentAverage) }} $</p>
      <p>Property Median  Price : {{ formatPrice(activeNeighborhood.propertyMedian) }} $</p>
      <p>Property Average Price : {{ formatPrice(activeNeighborhood.propertyAverage) }} $</p>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  data() {
    return {
      neighborhoods : [
        // {
        //   name: 'Ahuntsic-Cartierville',
        //   id: 'Mount Royal',
        //   rentalMedianPrice: 1600.0,
        //   rentalAveragePrice: 1680.975904,
        //   propertyMedianPrice: 799000,
        //   propertyAveragePrice: 985874.2
        // },
        // {
        //   name: 'Ville-Marie',
        //   id: 'Downtown',
        //   rentalMedianPrice: 1525.0,
        //   rentalAveragePrice: 1656.439325,
        //   propertyMedianPrice: 549000,
        //   propertyAveragePrice: 777261.1
        // },
        // {
        //   name: 'Ville-Marie',
        //   id: 'Old Montreal',
        //   rentalMedianPrice: 1790.0,
        //   rentalAveragePrice: 1908.714286,
        //   propertyMedianPrice: 749000,
        //   propertyAveragePrice: 970038.5
        // },
        {
          name: "Ville-Marie",
          id: "Ville-Marie",
          rentalMedianPrice: (1525.0 + 1790.0) / 2,
          rentalAveragePrice: (1656.439325 + 1908.714286) / 2,
          propertyMedianPrice: (549000 + 749000) / 2,
          propertyAveragePrice: (777261.1 + 970038.5) / 2
        },
        // {
        //   name: 'Villeray',
        //   id: 'Villeray',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1507.279182,
        //   propertyMedianPrice: 730000,
        //   propertyAveragePrice: 784354.4
        // },
        // {
        //   name: 'Villeray',
        //   id: 'Mile-End',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1507.279182,
        //   propertyMedianPrice: 747000,
        //   propertyAveragePrice: 950940.50
        // },
        {
          name: 'Rosemont',
          id: 'Rosemont',
          rentalMedianPrice: 1350.0,
          rentalAveragePrice: 1450.454008,
          propertyMedianPrice: 725000,
          propertyAveragePrice: 778202.7
        },
        {
          name: 'LaSalle',
          id: 'LaSalle',
          rentalMedianPrice: 1400.0,
          rentalAveragePrice: 1478.820000,
          propertyMedianPrice: 689000,
          propertyAveragePrice: 707471.4
        },
        // {
        //   name: 'Pierrefonds--Roxboro',
        //   id: 'Pointe-Claire',
        //   rentalMedianPrice: 1874.5,
        //   rentalAveragePrice: 1822.000000,
        //   propertyMedianPrice: 848000,
        //   propertyAveragePrice: 963587.4
        // },
        // {
        //   name: 'Pierrefonds',
        //   id: 'Pierrefonds',
        //   rentalMedianPrice: 1250.0,
        //   rentalAveragePrice: 1565.888889,
        //   propertyMedianPrice: 599000,
        //   propertyAveragePrice: 688436.7
        // },
        {
          name: "Pierrefonds--Roxboro",
          id: "Pierrefonds-Roxboro",
          rentalMedianPrice: (1874.5 + 1250.0) / 2,
          rentalAveragePrice: (1822.000000 + 1565.888889) / 2,
          propertyMedianPrice: (848000 + 599000) / 2,
          propertyAveragePrice: (963587.4 + 688436.7) / 2
        },
        // {
        //   name: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
        //   id: 'Pointe-aux-Trembles',
        //   rentalMedianPrice: 1380.0,
        //   rentalAveragePrice: 1366.680851,
        //   propertyMedianPrice: 425000,
        //   propertyAveragePrice: 511356.4
        // },
        // {
        //   name: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
        //   id: 'Riviere-des-Prairies',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1470.615385,
        //   propertyMedianPrice: 519800,
        //   propertyAveragePrice: 583211.1
        // },
        {
          name: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
          id: 'Pointe-aux-Trembles-Rivieres-des-Prairies',
          rentalMedianPrice: 1390.0,
          rentalAveragePrice: 1418.65,
          propertyMedianPrice: 472400,
          propertyAveragePrice: 547283.75
        },
        {
          name: 'Anjou',
          id: 'Anjou',
          rentalMedianPrice: 1400.0,
          rentalAveragePrice: 1404.783951,
          propertyMedianPrice: 574900,
          propertyAveragePrice: 605095.4
        },
        // {
        //   name: 'Ahuntsic',
        //   id: 'Ahuntsic',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1417.958716,
        //   propertyMedianPrice: 679000,
        //   propertyAveragePrice: 721648.7
        // },
        // {
        //   name: 'Cartierville',
        //   id: 'Cartierville',
        //   rentalMedianPrice: 1200.0,
        //   rentalAveragePrice: 1363.930233,
        //   propertyMedianPrice: 639000,
        //   propertyAveragePrice: 770669.2
        // },
        {
          name: 'Ahuntsic-Cartierville',
          id: 'Ahuntsic-Cartierville',
          rentalMedianPrice: 1300.0,
          rentalAveragePrice: 1390.94,
          propertyMedianPrice: 659000,
          propertyAveragePrice: 746158.95
        },
        {
          name: 'Saint-Laurent',
          id: 'Saint-Laurent',
          rentalMedianPrice: 1454.0,
          rentalAveragePrice: 1540.065060,
          propertyMedianPrice: 649000,
          propertyAveragePrice: 754067.3
        },
        {
          name: 'St-Leonard',
          id: 'Saint-Leonard',
          rentalMedianPrice: 1199.0,
          rentalAveragePrice: 1296.607843,
          propertyMedianPrice: 799900,
          propertyAveragePrice: 833919.1
        },
        // {
        //   name: 'Notre-Dame-de-Grace',
        //   id: 'Notre-Dame-de-Grace',
        //   rentalMedianPrice: 1550.0,
        //   rentalAveragePrice: 1590.358663,
        //   propertyMedianPrice: 774450,
        //   propertyAveragePrice: 880890.5
        // },
        // {
        //   name: 'Cote-des-Neiges',
        //   id: 'Cote-St-Luc',
        //   rentalMedianPrice: 1449.0,
        //   rentalAveragePrice: 1480.889088,
        //   propertyMedianPrice: 859000,
        //   propertyAveragePrice: 842600.0
        // },
        // {
        //   name: 'Cote-des-Neiges',
        //   id: 'Cote-des-Neiges',
        //   rentalMedianPrice: 1399.0,
        //   rentalAveragePrice: 1517.972571,
        //   propertyMedianPrice: 649000,
        //   propertyAveragePrice: 815859.7
        // },
        // {
        //   name: 'Cote-des-Neiges--Notre-Dame-de-Grace',
        //   id: 'Cote-des-Neiges--Notre-Dame-de-Grace',
        //   rentalMedianPrice: 1550.0,
        //   rentalAveragePrice: 1590.358663,
        //   propertyMedianPrice: 774450,
        //   propertyAveragePrice: 880890.5
        // },
        {
          name: 'Cote-des-Neiges--Notre-Dame-de-Grace',
          id: 'Cote-des-Neiges--Notre-Dame-de-Grace-Cote-St-Luc',
          rentalMedianPrice: 1466.0,
          rentalAveragePrice: 1529.740107,
          propertyMedianPrice: 760816.67,
          propertyAveragePrice: 846450.07
        },
        {
          name: 'Outremont',
          id: 'Outremont',
          rentalMedianPrice: 1700.0,
          rentalAveragePrice: 1866.566406,
          propertyMedianPrice: 1100000,
          propertyAveragePrice: 1526859.0
        },
        // {
        //   name: 'Outremont',
        //   id: 'Westmount',
        //   rentalMedianPrice: 1630.0,
        //   rentalAveragePrice: 1759.808511,
        //   propertyMedianPrice: 950000,
        //   propertyAveragePrice: 1077857.0
        // },
        {
          name: 'Montreal-Nord',
          id: 'Montreal-Nord',
          rentalMedianPrice: 980.0,
          rentalAveragePrice: 1098.339064,
          propertyMedianPrice: 525000,
          propertyAveragePrice: 579591.1
        },
        // {
        //   name: "Ile-des-Soeurs",
        //   id: "L'Ile-Des-Soeurs",
        //   rentalMedianPrice: 1770.5,
        //   rentalAveragePrice: 1948.350000,
        //   propertyMedianPrice: 748000,
        //   propertyAveragePrice: 938610.2
        // },
        // {
        //   name: 'Verdun',
        //   id: 'Verdun',
        //   rentalMedianPrice: 1650.0,
        //   rentalAveragePrice: 1799.412698,
        //   propertyMedianPrice: 725000,
        //   propertyAveragePrice: 787254.6
        // },
        {
          name: "Verdun--Ile-des-Soeurs",
          id: "L'Ile-Des-Soeurs/Verdun",
          rentalMedianPrice: 1710.25,
          rentalAveragePrice: 1873.881349,
          propertyMedianPrice: 736500,
          propertyAveragePrice: 862932.4
        },
        // {
        //   name: 'Maisonneuve',
        //   id: 'Maisonneuve',
        //   rentalMedianPrice: 1495.0,
        //   rentalAveragePrice: 1529.182482,
        //   propertyMedianPrice: 529000,
        //   propertyAveragePrice: 642745.6
        // },
        // {
        //   name: 'Mercier',
        //   id: 'Mercier',
        //   rentalMedianPrice: 1350.0,
        //   rentalAveragePrice: 1393.450172,
        //   propertyMedianPrice: 558000,
        //   propertyAveragePrice: 582600.2
        // },
        // {
        //   name: 'Hochelaga',
        //   id: 'Hochelaga',
        //   rentalMedianPrice: 1380.0,
        //   rentalAveragePrice: 1392.011136,
        //   propertyMedianPrice: 499900,
        //   propertyAveragePrice: 682186.5
        // },
        {
          name: "Mercier-Hochelaga-Maisonneuve",
          id: "Maisonneuve / Mercier / Hochelaga",
          rentalMedianPrice: 1408.33,
          rentalAveragePrice: 1438.21,
          propertyMedianPrice: 528966.67,
          propertyAveragePrice: 635844.1
        },
        {
          name: 'Lachine',
          id: 'Lachine',
          rentalMedianPrice: 1450.0,
          rentalAveragePrice: 1492.789474,
          propertyMedianPrice: 595000,
          propertyAveragePrice: 623520.4
        },
        // {
        //   name: 'Saint-Michel',
        //   id: 'Saint-Michel',
        //   rentalMedianPrice: 1300.0,
        //   rentalAveragePrice: 1284.151807,
        //   propertyMedianPrice: 645000,
        //   propertyAveragePrice: 658229.8
        // },
        // {
        //   name: 'Parc-Extension',
        //   id: 'Parc-Extension',
        //   rentalMedianPrice: 1250.0,
        //   rentalAveragePrice: 1293.729730,
        //   propertyMedianPrice: 695000,
        //   propertyAveragePrice: 702955.3
        // },
        // {
        //   name: 'Villeray',
        //   id: 'Villeray/Mile-End',
        //   rentalMedianPrice: 1400.0,
        //   rentalAveragePrice: 1507.279182,
        //   propertyMedianPrice: 738500,
        //   propertyAveragePrice: 867647.45
        // },
        {
          name: "Villeray-Saint-Michel-Parc-Extension",
          id: "Saint-Michel_Parc-Extension_Villeray-Mile-End",
          rentalMedianPrice: 1316.67,
          rentalAveragePrice: 1361.72,
          propertyMedianPrice: 692833.33,
          propertyAveragePrice: 742944.18
        },
        // {
        //   name: 'Mercier',
        //   id: 'Montreal East',
        //   rentalMedianPrice: 1050.0,
        //   rentalAveragePrice: 1112.529617,
        //   propertyMedianPrice: 495000,
        //   propertyAveragePrice: 635380.0
        // },
        // {
        //   name: 'Sud-Ouest',
        //   id: 'Saint-Henri',
        //   rentalMedianPrice: 1700.0,
        //   rentalAveragePrice: 1750.290441,
        //   propertyMedianPrice: 599000,
        //   propertyAveragePrice: 742463.3
        // },
        // {
        //   name: 'Sud-Ouest',
        //   id: 'Pointe-Saint-Charles',
        //   rentalMedianPrice: 1700.0,
        //   rentalAveragePrice: 1772.994118,
        //   propertyMedianPrice: 725000,
        //   propertyAveragePrice: 869170.5
        // },
        // {
        //   name: 'Sud-Ouest',
        //   id: 'Griffintown',
        //   rentalMedianPrice: 1650.0,
        //   rentalAveragePrice: 1740.761128,
        //   propertyMedianPrice: 508000,
        //   propertyAveragePrice: 622031.7
        // },
        {
          name: "Sud-Ouest",
          id: "Sud-Ouest",
          rentalMedianPrice: (1700.0 + 1700.0 + 1650.0) / 3,
          rentalAveragePrice: (1750.290441 + 1772.994118 + 1740.761128) / 3,
          propertyMedianPrice: (599000 + 725000 + 508000) / 3,
          propertyAveragePrice: (742463.3 + 869170.5 + 622031.7) / 3
        },
        // {
        //   name: 'Sainte-Genevieve',
        //   id: 'Sainte-Genevieve',
        //   rentalMedianPrice: 1525.0,
        //   rentalAveragePrice: 1631.875000,
        //   propertyMedianPrice: 539000,
        //   propertyAveragePrice: 588704.9
        // },
        // {
        //   name: "L'Ile-Bizard",
        //   id: "L'Ile-Bizard",
        //   rentalMedianPrice: 1500.0,
        //   rentalAveragePrice: 1573.333333,
        //   propertyMedianPrice: 825000,
        //   propertyAveragePrice: 1089686.0
        // },
        {
          name: "L'Ile-Bizard--Sainte-Genevieve",
          id: "L'Ile-Bizard/Sainte-Genevieve",
          rentalMedianPrice: 1512.5,
          rentalAveragePrice: 1602.61,
          propertyMedianPrice: 682000,
          propertyAveragePrice: 839195.45
        },
        {
          name: 'Plateau-Mont-Royal',
          id: 'Plateau Mont-Royal',
          rentalMedianPrice: 1579.5,
          rentalAveragePrice: 1665.085770,
          propertyMedianPrice: 609000,
          propertyAveragePrice: 871349.9
        },
      ],
      geoJsonFeatures: [],
      matchedNeighborhoods: [],
      activeNeighborhood: null,
      tooltipX: 0,
      tooltipY: 0,
      viewBox: '0 0 100 100',
      projection: null,
      path: null,
    }
  },
  mounted() {
    this.loadGeoJson();
  },
  methods: {
    loadGeoJson() {
      try {
        const geoJsonData = require('@/assets/montreal.json');
        // console.log(this.geoJsonFeatures);
        this.geoJsonFeatures = geoJsonData.features;
        this.matchNeighborhoods();
        this.setupProjection();
        console.log(this.matchedNeighborhoods);
      } catch (error) {
        console.error('Error loading GeoJSON:', error);
      }
    },
    matchNeighborhoods() {
      this.matchedNeighborhoods = this.neighborhoods.map(neighborhood => {
        const match = this.geoJsonFeatures.find(feature => 
          feature.properties.name.toLowerCase().includes(neighborhood.name.toLowerCase())
        //   feature.properties.name.toLowerCase() === neighborhood.name.toLowerCase()
        );
        if (! match) {
            console.log(neighborhood.name);
        }
        return {
          ...neighborhood,
          geometry: match ? match.geometry : null,
        };
      });
    },
    setupProjection() {
      const features = this.matchedNeighborhoods.filter(n => n.geometry);
      const bounds = d3.geoBounds({ type: 'FeatureCollection', features });
      const center = d3.geoCentroid({ type: 'FeatureCollection', features });
      console.log('Bounds:', bounds);
      console.log('Center:', center);

      
      this.projection = d3.geoMercator()
        .center(center)
        .fitSize([500, 500], { type: 'FeatureCollection', features });
      
      this.path = d3.geoPath().projection(this.projection);
      // console.log('Path for first feature:', this.pathForFeature(this.matchedNeighborhoods[0]));

      
    //   const [[x0, y0], [x1, y1]] = bounds;
    //   this.viewBox = `${x0} ${y0} ${x1 - x0} ${y1 - y0}`;
      this.viewBox = `0 0 500 500`;
        console.log(this.viewBox);
    },
    pathForFeature(feature) {
        const pathData = this.path(feature.geometry);
        // console.log('Path data:', pathData);
        return pathData;
    },
    formatPrice(price) {
      return new Intl.NumberFormat().format(Math.round(price));
    },
    centroid(feature) {
        if (!feature || !feature.geometry) {
            console.warn('Invalid feature geometry:', feature);
            return [0, 0]; // Fallback coordinates if geometry is missing
        }
        
        const centroid = this.path.centroid(feature.geometry);
        // console.log('Centroid for first feature:', this.centroid(this.matchedNeighborhoods[0]));

        
        if (isNaN(centroid[0]) || isNaN(centroid[1])) {
            console.warn('Centroid calculation for ' + name + 'resulted in NaN for feature:')
            console.log(feature);
            return [0, 0]; // Fallback coordinates in case of NaN
        }
        
        return centroid;
    },
    getColor(neighborhoodName) {
      let hue;
      if (neighborhoodName == 'Saint-Laurent' || neighborhoodName == 'Pointe-aux-Trembles-Rivieres-des-Prairies') {
        hue = (neighborhoodName.charCodeAt(5) * 15) % 360;
      } else {
        hue = (neighborhoodName.charCodeAt(3) * 15) % 360;
      }
      return `hsl(${hue}, 70%, 80%)`;
    },
    showTooltip(neighborhood) {
        this.activeNeighborhood = {
            name: neighborhood.name,
            rentMedian: neighborhood.rentalMedianPrice,
            rentAverage: neighborhood.rentalAveragePrice,
            propertyMedian: neighborhood.propertyMedianPrice,
            propertyAverage: neighborhood.propertyAveragePrice,
        };
        
        // if (neighborhood.geometry) {
        //     const [x, y] = this.centroid(neighborhood);
            
        //     // Check if the centroid is valid
        //     if (!isNaN(x) && !isNaN(y)) {
        //     this.tooltipX = x;
        //     this.tooltipY = y - 5; // Offset the tooltip a bit upwards
        //     } else {
        //     console.warn('Invalid tooltip position for:', neighborhood);
        //     }
        // }
    },
    hideTooltip() {
      this.activeNeighborhood = null;
    }
  },
  computed: {
  validNeighborhoods() {
    return this.matchedNeighborhoods.filter(neighborhood => neighborhood.geometry);
    }
  }
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  //   }
  // }
}
</script>

<style scoped>
.montreal-map {
  width: 100%;
  max-width: 950px;
  padding: 0 50px 0 50px;
  margin: 0 auto;
  position: relative;
}
svg {
  width: 100%;
  height: auto;
}
path {
  stroke: none;
  stroke-width: 1;
}
path:hover {
  fill: rgb(0, 85, 255);
  opacity: 0.8;
}
.text-overlay {
  z-index: 10;
}
.tooltip-panel {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 20;
  text-align: justify;
}
</style>