<template>
  <div class="beta-tooltip-container">
    <span 
      class="info-icon" 
      :style="{ color: color }"
      @mouseover="showTooltip = true" 
      @mouseleave="showTooltip = false">
      i
    </span>
    <div v-if="showTooltip" class="tooltip">
      β &gt; 1: More volatile than the market.<br>
      β = 1: Matches market volatility.<br>
      β &lt; 1: Less volatile than the market.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#006AC3' // default color is blue
    }
  },
  data() {
    return {
      showTooltip: false
    };
  }
};
</script>

<style scoped>
.beta-tooltip-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid currentColor;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 10;
  opacity: 0.9;
  text-align: left;
}
</style>
