<template>
  <div class="form">
    <div :style="{marginTop: '15px'}" class="page-header" v-show="$vuetify.breakpoint.lgAndUp">
      <v-container>
        <v-row no-gutters>
          <v-col cols="7" >
            <h1 class="page-title"> Property Details Form</h1>
          </v-col>
          <v-col cols="5" >
          <div class="title-panel">
              <router-link to="/howto" class="page-link">How-To</router-link>
              <router-link to="/summary" class="page-link">Summary</router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div :style="{marginTop: '15px'}" class="page-header-mobile" v-show="!$vuetify.breakpoint.lgAndUp">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" >
            <h1 class="page-title-mobile"> Property Details Form</h1>
          </v-col>
          <v-col cols="12" >
            <div class="title-panel-mobile">
              <router-link to="/howto" class="page-link">How-To</router-link>
              <router-link to="/summary" class="page-link">Summary</router-link>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- TODO -->
    <form @submit.prevent="submitForm">
      <v-container>
        <v-row>
          <v-col cols="12" lg="5">
            <div :style="{ border: '1px solid #2c3e50', borderRadius: ' 10px 0 0 10px', padding: '15px', marginLeft: '00px'}"  class="left-panel">
            <!-- <div :style="{ border: '2px solid #2c3e50', borderRadius: '10px', padding: '15px', marginLeft: '00px'}"  class="left-panel"> -->
              <div class="general-info">
                <h3 class="sub-panel-title font-weight-500 font-spacing">General Information</h3>
                  <v-container class="no-bottom-pad">
                    <v-row no-gutters>
                      <v-col   cols="6">
                        <label class="has-renting-unit-details font-weight-500">
                          Has Rental Unit Details:
                          <input
                            type="checkbox"
                            v-model="formData.has_renting_unit_details"
                          />
                        </label>
                      </v-col>
                      <v-col  cols="6">
                        <label>
                          <v-container class="no-bottom-pad no-top-pad">
                            <v-row no-gutters>
                              <v-col   cols="6">
                                <div class="centered-element font-weight-500">
                                 Risk Free Rate: 
                                </div>
                              </v-col>
                              <v-col   cols="6">
                                <div class="special-input-container">  
                                  <!-- <input
                                    type="number" class="inputNumber tinyInputNumber no-spinner"
                                    v-model="formData.property_details.risk_free_rate"
                                  /> -->
                                  <!-- <span>{{ formattedPercentage }}</span> -->
                                    <input
                                    :value="formattedValue" class="inputNumber tinyInputNumber no-spinner"
                                    @input="updateValue"
                                    @blur="formatValue"
                                    @focus="prepareForEdit"
                                  >

                                  <div class="special-unit-box">%</div>
                                </div>
                              </v-col>
                            </v-row>
                            </v-container>
                        </label>
                      </v-col>
                    </v-row>
                  </v-container>
                  <div class="property-details"> 
                    <h3 class="sub-panel-title font-weight-500 font-spacing">Property Details</h3>
                    <v-container>
                      <v-row>
                      <v-col cols="6">
                        <label>
                        <div class="input-label">Neighborhood:</div>
                        <drop-down
                          v-model="neighborhood"
                          :options="categories.neighborhood"
                          :placeholder="'Search neighborhood'"
                          class="form-dropdown"
                          />
                        </label>
                      </v-col>
                      <v-col cols="6">
                          <label>
                        <div class="input-label">Source:</div>
                        <drop-down
                          v-model="formData.property_details.source"
                          :options="categories.source"
                          :placeholder="'Select an source'"
                          class="dropdown-style"
                          />
                        </label>
                      </v-col>
                      <v-col cols="6">
                        <label>
                        <div class="input-label">Property Type:</div>
                        <drop-down
                          v-model="formData.property_details.property_type"
                          :options="categories.property_type"
                          :placeholder="'Select an option'"
                          />
                        </label>
                      </v-col>
                      <v-col cols="6">
                          <label>
                        <div class="input-label">Purchase Price:</div>
                        <div class="special-input-container">
                          <input
                              type="number" class="specialInputNumber no-spinner"
                              v-model="formData.property_details.purchase_price"
                              placeholder="Enter a price"
                          />
                          <div class="unit-box">$</div>
                        </div>
                        </label>
                      </v-col>
                      <v-col cols="6"> 
                          <label>
                        <div class="input-label">Potential Gross Revenues (Annual):</div>
                        <div class="special-input-container">
                          <input
                              type="number" class="specialInputNumber no-spinner"
                              v-model="formData.property_details.potential_gross_revenues"
                              placeholder="Required"
                          />
                          <div class="unit-box">$</div>
                        </div>
                          </label>
                      </v-col>
                      <v-col cols="6">
                          <label>
                        <div class="input-label">Operating Costs (Annual):</div>
                        <div class="special-input-container">
                          <input
                              type="number" class="specialInputNumber no-spinner"
                              v-model="formData.property_details.operating_costs"
                              placeholder="Enter a price"
                          />
                          <div class="unit-box">$</div>
                        </div>
                        </label>
                      </v-col>
                      <v-col cols="6">
                        <label>
                          <div class="input-label">Total Units:</div>
                          <input
                            type="number" class="inputNumber"
                            :disabled="formData.property_details.property_type == 'House' || formData.property_details.property_type == 'Condo'"
                            :style="formData.property_details.property_type == 'House' || formData.property_details.property_type == 'Condo' ? { opacity: 0.5, cursor: 'not-allowed' } : {}"
                            v-model="formData.property_details.total_units"
                          />
                        </label>
                      </v-col>
                      <v-col cols="3">
                        <label>
                          <div class="input-label">Residential Units:</div>
                          <input
                            type="number" class="inputNumber miniInputNumber"
                            :disabled="formData.property_details.property_type == 'House' || formData.property_details.property_type == 'Condo'"
                            :style="formData.property_details.property_type == 'House' || formData.property_details.property_type == 'Condo' ? { opacity: 0.5, cursor: 'not-allowed' } : {}"
                            v-model="formData.property_details.residential_units"
                          />
                        </label>
                      </v-col>
                      <v-col cols="3">
                        <label>
                          <div class="input-label">Business Units:</div>
                          <input
                            type="number" class="inputNumber miniInputNumber"
                            :disabled="formData.property_details.property_type == 'House' || formData.property_details.property_type == 'Condo'"
                            :style="formData.property_details.property_type == 'House' || formData.property_details.property_type == 'Condo' ? { opacity: 0.5, cursor: 'not-allowed' } : {}"
                            v-model="formData.property_details.business_units"
                          />
                        </label>
                      </v-col>
                      <v-col cols="6">
                          <label>
                        <div class="input-label">Number of Parkings:</div>
                        <input
                          type="number" class="inputNumber"
                          v-model="formData.property_details.no_of_parkings"
                        />
                          </label>
                      </v-col>
                      <v-col cols="6">          
                          <label>
                        <div class="input-label">Parking Type:</div>
                        <drop-down
                          v-model="formData.property_details.parking_type"
                          :options="categories.parking_type"
                          :placeholder="'Select an option'"
                          class="dropdown-style"
                          />
                      </label>
                      </v-col>
                      <v-col cols="6">
                          <label>
                        <div class="input-label">Construction Date:</div>
                        <input
                          type="number" class="inputNumber no-spinner"
                          v-model="formData.property_details.construction_date"
                          placeholder="Enter a year"
                        />
                          </label>
                      </v-col>
                      <v-col cols="6">
                          <label>
                        <div class="input-label">Construction Status:</div>
                        <drop-down
                          v-model="formData.property_details.age_status"
                          :options="categories.age_status"
                          :placeholder="'Select an option'"
                          class="dropdown-style"
                          />
                          </label>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="7">
            <div :style="{ border: '0px solid #D4AF37', outline: '1px solid #FFD700', outlineRadius: ' 0 5px 0 0', borderRadius: ' 0 5px 0 0', padding: '15px', marginRight: '0px'}">
            <!-- <div :style="{ border: '0px solid #D4AF37', outline: '2px solid #FFD700', outlineRadius: '10px', borderRadius: '10px', padding: '15px', marginRight: '0px'}"> -->
              <h3 class="sub-panel-title font-weight-500 font-spacing">More Features</h3>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <div class="location-accessibility">
                      <h2 class="font-weight-600">
                        Location & Accessibility
                        <button
                          type="button"
                          @click="toggleSection('location_and_accessibility')"
                        >
                          <i
                            :class="[
                              'fas',
                              'fa-chevron-down',
                              {
                                rotateArrow: sections.location_and_accessibility,
                              },
                            ]"
                          ></i>
                        </button>
                      </h2>
                      <transition
                        name="slide"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <div
                          class="textright"
                          v-if="sections.location_and_accessibility"
                        >
                          <label>
                            Close to Body of Water:
                            <input
                              type="checkbox"
                              v-model="
                                formData.property_details.close_to_body_of_water
                              "
                            /> </label
                          ><br />
                          <label>
                            Adapted for Reduced Mobility:
                            <input
                              type="checkbox"
                              v-model="
                                formData.property_details
                                  .adapted_for_reduced_mobility
                              "
                            /> </label
                          ><br />
                          <label>
                            Elevator:
                            <input
                              type="checkbox"
                              v-model="formData.property_details.elevator"
                            /> </label
                          ><br />
                        </div>
                      </transition>
                    </div>
                    <div class="heating-cooling">
                      <h2 class="font-weight-600">
                        Heating & Cooling
                        <button
                          type="button"
                          @click="toggleSection('heating_and_cooling')"
                        >
                          <i
                            :class="[
                              'fas',
                              'fa-chevron-down',
                              { rotateArrow: sections.heating_and_cooling },
                            ]"
                          ></i>
                        </button>
                      </h2>
                      <transition
                        name="slide"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <div
                          class="textright"
                          v-if="sections.heating_and_cooling"
                        >
                          <label>
                            Stove:
                            <drop-down
                              v-model="formData.property_details.stove"
                              :options="categories.stove"
                              :placeholder="'Pick a stove type'"
                              class="dropdown-style-more-feats"
                              />
                          </label>
                          <br />
                            <label>
                            Fireplace:
                            <input
                              type="checkbox"
                              v-model="formData.property_details.fireplace"
                            />
                          </label>
                          <br />
                          <label>
                            Heat Pump:
                            <input
                              type="checkbox"
                              v-model="formData.property_details.heat_pump"
                            /> </label
                          ><br />
                          <label>
                            Air Conditioner:
                            <input
                              type="checkbox"
                              v-model="
                                formData.renting_details.air_conditioner_included
                              "
                            /> </label
                          ><br />
                        </div>
                      </transition>
                    </div>
                    <div class="property-features">
                      <h2 class="font-weight-600">
                        Property Features
                        <button
                          type="button"
                          @click="toggleSection('property_features')"
                        >
                          <i
                            :class="[
                              'fas',
                              'fa-chevron-down',
                              { rotateArrow: sections.property_features },
                            ]"
                          ></i>
                        </button>
                      </h2>
                      <transition
                        name="slide"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <div class="textright" v-if="sections.property_features">
                          <label>
                            Area (ft<sup>2</sup>): <br/>
                            <input
                              type="number" class="inputNumberMoreFeats no-spinner"
                              v-model="formData.property_details.area"
                            />
                            </label>
                            <br />
                          <label>
                            Build Style:
                            <!-- <input
                              type="text"
                              v-model="formData.property_details.build_style"
                            /> -->
                            <drop-down
                              v-model="formData.property_details.build_style"
                              :options="categories.build_style"
                              :placeholder="'Build style...'"
                              class="dropdown-style-more-feats"
                              />
                            </label>
                            <!-- <br /> -->
                          <label>
                            Lease Duration in Months:
                            <br />
                            <input
                              type="number" class="inputNumberMoreFeats no-spinner"
                              v-model="
                                formData.renting_details.lease_duration_in_months
                              "
                              inputmode="numeric"
                            />
                          </label>
                            <br />
                          <label>
                            Furnished:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.furnished"
                            />
                          </label>
                          <br />
                           <label>
                            Private Exterior Spaces:
                            <input
                              type="checkbox"
                              v-model="
                                formData.renting_details
                                  .private_exterior_spaces_included
                              "
                            />
                          </label>
                          <br />
                          <!-- <br /> -->
                          <label>
                            Details from Description:
                            <input
                              type="checkbox"
                              v-model="
                                formData.renting_details.details_from_description
                              "
                            /> </label
                          ><br />
                        </div>
                      </transition>
                    </div>
                    <div class="utilities">
                      <h2 class="font-weight-600">
                        Utilities & Inclusions
                        <button
                          type="button"
                          @click="toggleSection('utilities_and_inclusions')"
                        >
                          <i
                            :class="[
                              'fas',
                              'fa-chevron-down',
                              { rotateArrow: sections.utilities_and_inclusions },
                            ]"
                          ></i>
                        </button>
                      </h2>
                      <transition
                        name="slide"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <div
                          class="textright"
                          v-if="sections.utilities_and_inclusions"
                        >
                          <label>
                            Electricity:
                            <input
                              type="checkbox"
                              v-model="
                                formData.renting_details.electricity_included
                              "
                            /> </label
                          ><br />
                          <label>
                            Heating:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.heating_included"
                            /> </label
                          ><br />
                          <label>
                            Water:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.water_included"
                            /> </label
                          ><br />
                          <label>
                            Wifi:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.wifi_included"
                            /> </label
                          ><br />
                          <label>
                            Parking:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.parking_included"
                            />
                            </label>
                            <br />
                          <label>
                            TV:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.tv_included"
                            /> </label
                          ><br />
                        </div>
                      </transition>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="recreational">
                      <h2 class="font-weight-600">
                        Recreational & Luxury Amenities
                        <button
                          type="button"
                          @click="
                            toggleSection('recreational_and_luxury_amenities')
                          "
                        >
                          <!-- <i :class="{'rotateArrow': sections.recreational_and_luxury_amenities}" class="fas fa-chevron-down"></i> -->
                          <i
                            :class="[
                              'fas',
                              'fa-chevron-down',
                              {
                                rotateArrow:
                                  sections.recreational_and_luxury_amenities,
                              },
                            ]"
                          ></i>
                        </button>
                      </h2>
                      <transition
                        name="slide"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <div
                          class="textright"
                          v-if="sections.recreational_and_luxury_amenities"
                        >
                          <label>
                            Has Pool:
                            <input
                              type="checkbox"
                              v-model="formData.property_details.has_pool"
                            /> </label
                          ><br />
                          <label>
                            Pool Ground Level:
                            <!-- <input
                              type="text"
                              v-model="
                                formData.property_details.pool_ground_level
                              "
                            /> -->
                            <drop-down
                                  v-model="formData.property_details.pool_ground_level"
                                  :options="categories.pool_ground_level"
                                  :placeholder="'Pool ground level...'"
                                  class="dropdown-style-more-feats"
                                  :disabled="!formData.property_details.has_pool"
                                  :style="!formData.property_details.has_pool ? { opacity: 0.5, cursor: 'not-allowed' } : {}"
                                  />
                            </label>
                          <br />
                          <label>
                            Is Pool Heated:
                            <!-- <input
                              type="text"
                              v-model="formData.property_details.is_pool_heated"
                            /> -->
                            <drop-down
                                v-model="formData.property_details.is_pool_heated"
                                :options="categories.is_pool_heated"
                                :placeholder="'Pool heat...'"
                                class="dropdown-style-more-feats"
                                  :disabled="!formData.property_details.has_pool"
                                  :style="!formData.property_details.has_pool ? { opacity: 0.5, cursor: 'not-allowed' } : {}"
                                />
  
                            </label>
                            <br />
                          <label>
                            Pool Location:
                          <drop-down
                            v-model="formData.property_details.pool_location"
                            :options="categories.pool_location"
                            :placeholder="'Select a pool location'"
                            class="dropdown-style-more-feats"
                            :disabled="!formData.property_details.has_pool"
                            :style="!formData.property_details.has_pool ? { opacity: 0.5, cursor: 'not-allowed' } : {}"
                            />
                            </label>
                            <br />
                          <label>
                            Gym:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.gym"
                            /> </label
                          ><br />
                          <label>
                            Concierge:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.concierge"
                            /> </label
                          ><br />
                          <label>
                            Security 24hrs:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.security24hrs"
                            /> </label
                          ><br />
                        </div>
                      </transition>
                    </div>
                    <div class="household-appliances">
                      <h2 class="font-weight-600">
                        Household Appliances & Facilities
                        <button
                          type="button"
                          @click="
                            toggleSection('household_appliances_and_facilities')
                          "
                        >
                          <i
                            :class="
                              sections.household_appliances_and_facilities
                                ? 'fas fa-chevron-down rotateArrow'
                                : 'fas fa-chevron-down'
                            "
                          ></i>
                          <!-- <i :class="['fas', 'fa-chevron-down', { 'rotateArrow': sections.household_appliances_and_facilities }]"></i> -->
                        </button>
                      </h2>
                      <transition
                        name="slide"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <div
                          class="textright"
                          v-if="sections.household_appliances_and_facilities"
                        >
                          <label>
                            Laundry in Unit:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.laundry_in_unit"
                            /> </label
                          ><br />
                          <label>
                            Laundry in Building:
                            <input
                              type="checkbox"
                              v-model="
                                formData.renting_details.laundry_in_building
                              "
                            /> </label
                          ><br />
                          <label>
                            Dishwasher:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.dishwasher"
                            /> </label
                          ><br />
                          <label>
                            Fridge:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.fridge"
                            /> </label
                          ><br />
                          <label>
                            Bicycle Parking:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.bicycle_parking"
                            /> </label
                          ><br />
                          <label>
                            Storage Space:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.storage_space"
                            /> </label
                          ><br />
                        </div>
                      </transition>
                    </div>
                    <div class="policies-permissions">
                      <h2 class="font-weight-600">
                        Policies & Permissions
                        <button
                          type="button"
                          @click="toggleSection('policies_and_permissions')"
                        >
                          <!-- <i :class="['fas', 'fa-chevron-down', { 'rotateArrow':  sections.policies_and_permissions}]"></i> -->
                          <i
                            :class="[
                              'fas',
                              'fa-chevron-down',
                              { rotateArrow: sections.policies_and_permissions },
                            ]"
                          ></i>
                        </button>
                      </h2>
                      <transition
                        name="slide"
                        @before-enter="beforeEnter"
                        @enter="enter"
                        @leave="leave"
                      >
                        <div
                          class="textright"
                          v-if="sections.policies_and_permissions"
                        >
                          <label>
                            Animal Friendly:
                            <input
                              type="checkbox"
                              v-model="formData.renting_details.animal_friendly"
                            /> </label
                          ><br />
                          <label>
                            Smoking Allowed:
                            <input
                              type="checkbox"
                              v-model="
                                formData.renting_details.smoking_allowed_included
                              "
                            /> </label
                          ><br />
                        </div>
                      </transition>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <br/>
            <div :style="{ border: '0px solid #d1d1d1', outline: '1px solid #e3e6e4', outlineRadius: '0px', borderRadius: ' 0 0 5px 0', padding: '15px', marginRight: '0px'}">
              <div class="renting-units"
                   :disabled="!formData.has_renting_unit_details"
                   :style="!formData.has_renting_unit_details ? { opacity: 0.5, cursor: 'not-allowed' } : {}">
                <h3 class="sub-panel-title font-weight-500 font-spacing">Rental Units</h3>
                <v-container>
                  <v-row >
                    <v-col v-for="(unit, index) in formData.renting_units" :key="index" cols="6" sm="5" lg="4" xl="3">
                      <div class="unitPanel" :style="{ border: '1px solid #3271a8', paddingTop: '0px'}">
                        <v-container :style="{paddingTop: '0px!important'}">
                          <v-row >
                            <v-col cols="6" class="unitTitle" :style="{paddingTop: '3px!important', paddingLeft: '5px!important'}">
                              <div class="unitInputLabel unit-title">Unit {{ index + 1 }}</div>
                            </v-col>
                            <v-col cols="6" class="unitTitle">
                              <button :disabled="!formData.has_renting_unit_details" class="removeUnitButton" @click.prevent="removeUnit(index)">x</button>
                            </v-col>
                          </v-row >
                        </v-container>
                        <v-container :style="{paddingTop: '0px!important'}">
                          <v-row no-gutters>
                            <v-col cols="6" class="unitInputLabel">
                              <label>
                                Rooms:
                              </label>
                            </v-col>
                            <v-col cols="6">
                              <label>
                                <input type="number" :disabled="!formData.has_renting_unit_details" class="unitInputNumber" v-model="unit.no_of_rooms" min="0" step="1"/>
                              </label>
                            </v-col>
                            <v-col cols="6" class="unitInputLabel">
                              <label>
                                Bathrooms:
                              </label>
                            </v-col>
                            <v-col cols="6">
                              <label>
                                <input type="number" class="unitInputNumber" :disabled="!formData.has_renting_unit_details" v-model="unit.no_of_bathrooms" min="0" step="1"/>
                              </label>
                            </v-col>
                            <v-col cols="6" class="unitInputLabel">
                              <label>
                                Area (ft<sup>2</sup>):
                              </label>
                            </v-col>
                            <v-col cols="6">
                              <label>
                                <input type="number" :disabled="!formData.has_renting_unit_details" class="unitInputNumber" v-model="unit.area"  min="0"  step="20"/>
                              </label>
                            </v-col>
                          </v-row>
                        </v-container>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <button class="button" :disabled="!formData.has_renting_unit_details" @click.prevent="addUnit">Add Unit</button>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <button class="button font-spacing" type="submit" @click="fetchAlpha">Submit</button>
    </form>

    <!-- <pre>{{ formData }}</pre> -->
  </div>
</template>

<script>
// import VueSimpleSearchDropdown from 'vue-simple-search-dropdown'
// import { mapState, mapActions } from 'vuex';
import DropDown from './DropDown.vue';
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://alpha.realestatealpha.ai';
// axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.baseURL = 'https://deploy-rea.onrender.com';
// axios.defaults.baseURL = 'http://127.0.0.1:5000';

export default {
  components: {
    // VueSimpleSearchDropdown,
    DropDown
  },
  props: {
    initialFormData: {
      type: Object,
      default: null, // Allows the prop to be null
    },
  },
  data() {
    return {
      alphaRes : {'alpha' : 1},
      categories: {
        source : [
            { id: 'DuProprio', name: 'DuProprio' },
            { id: 'Centris', name: 'Centris' },
            { id: 'Other', name: 'Other' }
          ],
        build_style : [
            { id: 'Divided' , name: 'Divided' },
            { id: 'Detached' , name: 'Detached' },
            { id: 'N/S', name: 'N/S' },
            { id: 'Other' , name: 'Other' },
            { id: 'Undivided' , name: 'Undivided' },
            { id: 'Semi-detached', name: 'Semi-detached' },
            { id: 'Attached' , name: 'Attached' }
          ],
        is_pool_heated : [
            { id: 'N/A', name: 'N/A'   },
            { id: 'True', name: 'True'  },
            { id: 'False', name: 'False' }
          ],
        stove : [
            { id: 'None' , name: 'None'       },
            { id: 'Wood Stove', name: 'Wood Stove' },
            { id: 'Gas Stove', name: 'Gas Stove'  },
            { id: 'Oil Stove', name: 'Oil Stove'  }
          ],
        pool_location : [
            { id: 'N/A',     name: 'N/A' },
            { id: 'N/S',     name: 'N/S' },
            { id: 'Indoor',  name: 'Indoor' },
            { id: 'Both',    name: 'Both' },
            { id: 'Outdoor', name: 'Outdoor' }
          ],
        pool_ground_level : [
            { id: 'N/A',          name: 'N/A'            },
            { id: 'Inground',     name: 'Inground'       },
            { id: 'N/S',          name: 'N/S'            },
            { id: 'Above-Ground', name: 'Above-Ground'   },
            { id: 'Both',         name: 'Both'           }
          ],
        age_status : [
            { id: 'Normal' , name: 'Normal' },
            { id: 'Under construction', name: 'Under construction' },
            { id: 'New' , name: 'New' },
            { id: 'Century' , name: 'Century' },
            { id: 'Being Converted', name: 'Being Converted' },
            { id: 'Historic' , name: 'Historic' },
          ],
        parking_type : [
            { id:  'Driveway', name: 'Driveway'             },
            { id:  'None', name: 'None'                },
            { id: 'Driveway Garage', name: 'Driveway Garage'       },
            { id:  'Garage', name: 'Garage'                },
            { id: 'Driveway Double drive', name: 'Driveway Double drive' },
            { id: 'Double drive', name: 'Double drive'             },
            { id: 'Double drive Garage', name: 'Double drive Garage'             },
            { id: 'Driveway Double drive Garage' , name: 'Driveway Double drive Garage'             },
            { id: 'Carport', name: 'Carport'            },
          ],
        property_type : [
            { id: 'Condo' , name: 'Condo' },
            { id: 'House' , name: 'House' },
            { id: 'Triplex' , name: 'Triplex' },
            { id: 'Quadruplex' , name: 'Quadruplex' },
            { id: 'Duplex' , name: 'Duplex' },
            { id: 'N/S', name: 'N/S' },
            { id: 'Lot' , name: 'Lot' },
            { id: 'Quintuplex' , name: 'Quintuplex' },
            { id: 'Multiplex' , name: 'Multiplex' },
            { id:  'Commercial' , name: 'Commercial' }
        ],
        neighborhood : [
          {name: 'Pierrefonds', id: 'Pierrefonds'},
          {name: 'Mercier', id: 'Mercier'},
          {name: 'Mount Royal', id: 'Mount Royal'},
          {name: 'Downtown', id: 'Downtown'},
          {name: 'Villeray', id: 'Villeray'},
          {name: 'Rosemont', id: 'Rosemont'},
          {name: 'Griffintown', id: 'Griffintown'},
          {name: 'LaSalle', id: 'LaSalle'},
          {name: 'Pointe-Claire', id: 'Pointe-Claire'},
          {name: 'Pointe-aux-Trembles', id: 'Pointe-aux-Trembles'},
          {name: 'Ahuntsic', id: 'Ahuntsic'},
          {name: 'Anjou', id: 'Anjou'},
          {name: 'Cote-des-Neiges', id: 'Cote-des-Neiges'},
          {name: 'Cartierville', id: 'Cartierville'},
          {name: 'Saint-Laurent', id: 'Saint-Laurent'},
          {name: 'Saint-Leonard', id: 'Saint-Leonard'},
          {name: 'Notre-Dame-de-Grace', id: 'Notre-Dame-de-Grace'},
          {name: 'Riviere-des-Prairies', id: 'Riviere-des-Prairies'},
          {name: 'Outremont', id: 'Outremont'},
          {name: 'Verdun', id: 'Verdun'},
          {name: 'Montreal-Nord', id: 'Montreal-Nord'},
          {name: 'N/S', id: 'N/S'},
          {name: 'Longueuil', id: 'Longueuil'},
          {name: "L'Ile-Des-Soeurs", id: "L'Ile-Des-Soeurs"},
          {name: 'Old Montreal', id: 'Old Montreal'},
          {name: 'Mile-End', id: 'Mile-End'},
          {name: 'Maisonneuve', id: 'Maisonneuve'},
          {name: 'Lachine', id: 'Lachine'},
          {name: "L'Ile-Bizard", id: "L'Ile-Bizard"},
          {name: 'Saint-Michel', id: 'Saint-Michel'},
          {name: 'Parc-Extension', id: 'Parc-Extension'},
          {name: 'Montreal East', id: 'Montreal East'},
          {name: 'Saint-Henri', id: 'Saint-Henri'},
          {name: 'Westmount', id: 'Westmount'},
          {name: 'Pointe-Saint-Charles', id: 'Pointe-Saint-Charles'},
          {name: 'Sainte-Genevieve', id: 'Sainte-Genevieve'},
          {name: 'Hochelaga', id: 'Hochelaga'},
          {name: 'Plateau Mont-Royal', id: 'Plateau Mont-Royal'},
          {name: 'Cote-St-Luc', id:'Cote-St-Luc'}
         ]
      },
      isEditing: false,
      formData: {
        has_renting_unit_details: false,
        property_details: {
          source: "",
          potential_gross_revenues: null,
          no_of_parkings: 2,
          parking_type: "Garage",
          adapted_for_reduced_mobility: false,
          elevator: false,
          close_to_body_of_water: false,
          neighborhood: "",
          property_type: "",
          construction_date: null,
          age_status: "",
          risk_free_rate : 0.0472,
          estimates : 4, // TODO Potentially make this parameters adjustable from the UI
          total_units: 3,
          residential_units: 3,
          business_units: 0,
          fireplace: false,
          stove: "",
          heat_pump: false,
          area: null,
          has_pool: false,
          pool_ground_level: "N/A",
          is_pool_heated: "N/A",
          pool_location: "N/A",
          build_style: "",
          actual_price: null,
          purchase_price: null,
          operating_costs: null,
        },
        renting_details: {
          electricity_included: false,
          heating_included: false,
          wifi_included: false,
          parking_included: false,
          animal_friendly: false,
          laundry_in_unit: false,
          air_conditioner_included: false,
          private_exterior_spaces_included: false,
          smoking_allowed_included: false,
          furnished: false,
          lease_duration_in_months: null,
          details_from_description: false,
          water_included: false,
          tv_included: false,
          laundry_in_building: false,
          dishwasher: false,
          fridge: false,
          gym: false,
          concierge: false,
          security24hrs: false,
          bicycle_parking: false,
          storage_space: false,
        },
        renting_units: [
          {
            no_of_rooms: 0,
            no_of_bathrooms: 0,
            area: null,
          },
        ],
      },
      sections: {
        location_and_accessibility: false,
        heating_and_cooling: false,
        property_features: false,
        utilities_and_inclusions: false,
        recreational_and_luxury_amenities: false,
        household_appliances_and_facilities: false,
        policies_and_permissions: false,
      },
    };
  },
  // watch: {
  //   initialFormData: {
  //     immediate: true,
  //     handler(newValue) {
  //       if (newValue) {
  //         this.formData = { ...this.formData, ...newValue }; // Merge new values into formData
  //       }
  //     },
  //   },
  // },
  computed: {
    // ...mapState('formStore', ['formData']),
    formattedValue() {
      if (this.isEditing) {
        return this.formData.property_details.risk_free_rate.toString();
      } else {
        return `${(this.formData.property_details.risk_free_rate * 100)}`; //.toFixed(2)
      }
    },
    activePolicies() {
      return this.sections.policies_and_permissions;
    },
    neighborhood: {
      get() {
        return this.formData.property_details.neighborhood
      },
      set(value) {
        this.formData.property_details.neighborhood = value
      }
    }
  },
  methods: {
    updateValue(event) {
      this.formData.property_details.risk_free_rate = event.target.value.replace('%', '');
    },

    formatValue() {
      this.isEditing = false;
      this.formData.property_details.risk_free_rate = parseFloat(this.formData.property_details.risk_free_rate.toFixed(4));
    },

    prepareForEdit() {
      this.isEditing = true;
    },

    typecheck(formData) {
      formData.property_details.operating_costs = Number(formData.property_details.operating_costs);
      formData.property_details.risk_free_rate = Number(formData.property_details.risk_free_rate);
      formData.property_details.purchase_price = Number(formData.property_details.purchase_price);
      formData.property_details.total_units = Number(formData.property_details.total_units);
      formData.property_details.residential_units = Number(formData.property_details.residential_units);
      formData.property_details.business_units = Number(formData.property_details.business_units);
      formData.property_details.no_of_parkings = Number(formData.property_details.no_of_parkings);
      formData.property_details.actual_price = formData.property_details.purchase_price;
      formData.property_details.potential_gross_revenues = Number(formData.property_details.potential_gross_revenues);
      formData.property_details.revenues = formData.property_details.potential_gross_revenues;
      formData.property_details.is_pool_heated = formData.property_details.is_pool_heated === 'N/A' ? formData.property_details.is_pool_heated  :  Boolean(formData.property_details.is_pool_heated);
      if (formData.property_details.property_type == 'House' || formData.property_details.property_type == 'Condo') {
        formData.property_details.total_units = 1;
        formData.property_details.residential_units = 1;
        formData.property_details.business_units = 0;
      }
      if (formData.renting_units.length > 0) {
        formData.renting_units.forEach(unit => {
            if (unit.area !== null) {
              unit.area = Number(unit.area);
            } else {
              unit.area = -1;
            }
          });
      }

      return formData;
    },

    async checkSession() {
        try {
          const response = await axios.get('/api/check_session', {
          // const response = await axios.get('http://127.0.0.1:5000/api/check_session', {
            withCredentials: true
          });
          console.log("Session check response:", response.data);
          return response.data.logged_in;
        } catch (error) {
          console.error("Session check failed:", error);
          return false;
        }
      },

    async alphaRequest() {
        // if (!(await this.checkSession())) {
        //   this.$alert("Your session has expired. Please log in again.");
        //   // this.$router.push('/login');
        //   return false;
        // }

        try {
          const formData = this.typecheck(this.replaceNullValues(this.formData));
          console.log("Sending data:", formData);

          const response = await axios.post('https://alpha.realestatealpha.ai/api/compute_alpha', formData, {
          // const response = await axios.post('https://deploy-rea.onrender.com/api/compute_alpha', formData, {
          // const response = await axios.post('http://127.0.0.1:5000/api/compute_alpha', formData, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            }
          });

          this.response = response.data;
          this.alphaRes = response.data;
          console.log("Alpha response:", this.response);
          return true;
        } catch (error) {
          console.error("Error in alphaRequest:", error);
          
          if (error.response) {
            if (error.response.status === 401) {
              this.$store.commit('setAuth', false);
              this.$alert("Authentication failed. Please log in again.");
              this.$router.push('/login');
            } else {
              this.$alert(`Server error: ${error.response.data.message || 'Unknown error'}`);
            }
          } else if (error.request) {
            this.$alert("No response from server. Please check your network connection.");
          } else {
            this.$alert(`Request setup error: ${error.message}`);
          }
          
          return false;
        }
    },
    // async fetchAlpha() {
    //   console.log('Form data');
    //   console.log(this.formData);
    //   let unfilled_fields = this.verify_form(this.formData.property_details);
    //   console.log('Empty fields' + unfilled_fields.join(', '));
      
    //   if (unfilled_fields.length > 0) {
    //     this.$alert("The following fields are required : " + unfilled_fields.join(', ') + ".");
    //   } else {
    //     // Save form data to store before navigating
    //     await this.saveFormData(this.formData);
        
    //     let status = await this.alphaRequest();
        
    //     if(status) {
    //       console.log('Alpha Results : ')
    //       console.log(this.alphaRes.body)
    //       if (this.alphaRes.status == 'success') {
    //         this.$router.push({
    //           name: 'ShowAlpha',
    //           params: {
    //             alphaResults: this.alphaRes,
    //             neighborhood: this.formData.property_details.neighborhood
    //           }
    //         });
    //       } else {
    //         this.$alert("Unfortunately, we don't have enough data in the market you are interested in right now so we could not compute an alpha for your property. Try something else!");
    //       }
    //     }
    //   }
    // },

    async fetchAlpha() {
      console.log('Form data');
      console.log(this.formData);
      let unfilled_fields = this.verify_form(this.formData.property_details);
      console.log('Empty fields' + unfilled_fields.join(', '));
      if (unfilled_fields.length > 0) {
        this.$alert("The following fields are required : "  + unfilled_fields.join(', ') + ".");
      } else {
      
        let status = await this.alphaRequest();

        // let t = true;
        // if(t) {
        if(status) {
          console.log('Alpha Results : ')
          console.log(this.alphaRes.body)
          // if (t) {
          if (this.alphaRes.status == 'success') {

            this.$router.push({
              name: 'ShowAlpha',
              // path: '/showAlpha',
              // params: { alphaResults: a, neighborhood: this.formData.property_details.neighborhood, formData: this.formData}
              params: { alphaResults: this.alphaRes, neighborhood: this.formData.property_details.neighborhood, formData: this.formData }
            });
          } else {
            this.$alert("Unfortunately, we don't have enough data in the market you are interested in right now so we could not compute an alpha for your property. Try something else!");
          }
        }
      }
    },
    activeSection: function (section) {
      return this.sections[section];
    },
    submitForm() {
      console.log(this.formData);
      // Handle form submission
    },
    addUnit() {
      this.formData.renting_units.push({
        no_of_rooms: 0,
        no_of_bathrooms: 0,
        area: null,
      });
    },
    removeUnit(index) {
      this.formData.renting_units.splice(index, 1);
    },
    toggleSection(section) {
      this.sections[section] = !this.sections[section]; // Ensure reactivity
      // this.$set(this.sections, section, !this.sections[section]);
      console.log(this.sections[section]); // Check the updated value
    },
    beforeEnter(el) {
      el.style.height = "0";
    },
    enter(el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave(el) {
      el.style.height = el.scrollHeight + "px";
      setTimeout(() => {
        el.style.height = "0";
      });
    },
    replaceNullValues(obj) {
      let oldPro = obj.property_details;
      let oldRent = obj.renting_details;
      const newObj = {...oldPro};
      const newRent = {...oldRent};

      if ('potential_gross_revenues' in newObj && newObj.potential_gross_revenues === null) {
        newObj.potential_gross_revenues = -1;
      }

      if ('construction_date' in newObj && newObj.construction_date === null) {
        newObj.construction_date = -1;
      }

      if ('build_style' in newObj && (newObj.build_style === null || newObj.build_style.trim() === '')) {
        newObj.build_style = 'N/S';
      }

      if ('area' in newObj && newObj.area === null) {
        newObj.area = -1;
      }

      if ('stove' in newObj && newObj.stove.trim() === '') {
        newObj.stove = 'None';
      }

      if ('source' in newObj && newObj.source.trim() === 'Other') {
        newObj.source = 'DuProprio';
      }

      if ('age_status' in newObj && newObj.age_status.trim() === '') {
        newObj.age_status = 'Normal';
      }

      if ('lease_duration_in_months' in newRent && newRent.lease_duration_in_months === null) {
        newRent.lease_duration_in_months = 12;
      }

      obj.property_details = newObj;
      obj.renting_details = newRent;

      return obj;
    },
    verify_form(propertyDetails) {
      const requiredFields = [
        'source',
        'potential_gross_revenues',
        'parking_type',
        'neighborhood',
        'property_type',
        // 'construction_date',
        // 'age_status',
        // 'stove',
        // 'area',
        // 'build_style',
        // 'actual_price',
        'purchase_price',
        'operating_costs'
      ];

      const emptyFields = requiredFields.filter(field => {
        const value = propertyDetails[field];
        return value === null || (typeof value === 'string' && value.trim() === '');
      });

      return emptyFields;
    },
  },
  mounted() {
    console.log('Incoming Route Params:', this.$route.params);
    let initialFormData = this.$route.params.initialFormData;
    if (initialFormData != null) {
      this.formData = initialFormData;
    }
  },
  //   if (this.formData) {
  //     this.formData = JSON.parse(JSON.stringify(this.formData)); // Clone to avoid reactivity issues
  //   }
  // },
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  //   }
  //   if (this.storedFormData) {
  //     this.formData = JSON.parse(JSON.stringify(this.storedFormData));
  //   }
  // }
    created() {
      console.log('Initial Form Data:', this.initialFormData);
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
}
.justified {
  display: flex;
  align-items: justify;
}

label {
  margin-bottom: 1em;
}

button {
  margin-top: 1em;
}

pre {
  margin-top: 2em;
  background: #f4f4f4;
  padding: 1em;
  border: 1px solid #ddd;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(-50px);
}
.rotateArrow {
  transform: rotate(180deg) !important;
  transition: transform 0.3s;
}
.textright {
  text-align: right;
  padding-right: 130px;
}
.general-info {
  text-align: left;
  font-size: 18px !important;
}
.general-info h3 {
  text-align: left;
}
.form h2 {
  font-size: 18px !important;
}
.form h3 {
  font-size: 21px !important;
}
.form h4 {
  font-size: 23px !important;
}
.left-panel {
  border-width: 2em;
  border-color: #2c3e50;
  border-radius: 3px;
  border: '3px solid #2c3e50';
}
.button {
  /* background-color: #1a5baa; */
  color: #fff;
  font-weight: bold!important;
  padding: 22px 35px;
  background-color: #0051a5;
  -webkit-box-shadow: inset 0 0 0 1px #003168;
  box-shadow: inset 0 0 0 1px #003168;
}
.button:hover {
  /* background-color: #1a5baa; */
  color: #fff;
  font-weight: bold!important;
  padding: 22px 35px;
  background-color: #003168;
  -webkit-box-shadow: inset 0 0 0 1px #003168;
  box-shadow: inset 0 0 0 1px #003168;
}
.removeUnitButton {
  /* background-color: #1a5baa; */
  color: #fff;
  font-weight: bold!important;
  padding: 9px 25px;
  background-color: #ba6670;
  -webkit-box-shadow: inset 0 0 0 1px #ba6670;
  box-shadow: inset 0 0 0 1px #ba6670;
  /* background-color: #782323;
  -webkit-box-shadow: inset 0 0 0 1px #782323;
  box-shadow: inset 0 0 0 1px #782323; */
  margin-top: 0px!important;
  font-size: 14px;
}
.removeUnitButton:hover {
  /* background-color: #1a5baa; */
  color: #fff;
  font-weight: bold!important;
  background-color: #ba6670;
  -webkit-box-shadow: inset 0 0 0 1px #ba6670;
  box-shadow: inset 0 0 0 1px #ba6670;
  /* background-color: #5c1b1b;
  -webkit-box-shadow: inset 0 0 0 1px #782323;
  box-shadow: inset 0 0 0 1px #782323; */
}
.unitTitle {
  background: #f4f9ff;
  /* background: #a1b7d0; */
  padding: 0!important;
}
.miniInputNumber {
  width: 50%!important;
  /* width: 150px!important; */
}
.tinyInputNumber {
  width: 100px!important;
  margin-left: 0px!important;
  margin-right: 0px!important;
}
.inputNumber {
  border: 0.5px solid #a1b7d0;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 10px;
  width: auto;
  font-size: 16px!important;
}
.inputNumber:hover {
  border: 0.6px solid #5c7a9e;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 10px;
  width: auto;
  font-size: 16px!important;
}
.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #a1b7d0!important;
  /* Add any other styles you want for the disabled state */
}
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinner {
  -moz-appearance: textfield;
}
.inputNumberMoreFeats {
  border: 0.5px solid #a1b7d0;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 3px;
  width: 100px;
  font-size: 16px!important;
  appearance: none;
}
.inputNumberMoreFeats:hover {
  border: 0.6px solid #5c7a9e;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  font-size: 16px!important;
}
.dropdown-form{
    background: #fff;
    cursor: pointer;
    border: 1px solid #e7ecf5;
    border-radius: 3px;
    color: #333;
    display: block;
    font-size: .8em;
    padding: 6px;
    min-width: 150px;
    max-width: 250px;
}
.input-label {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 17px;
}
.form-dropdown {
  width: auto!important;
  border: 0.5px solid #a1b7d0;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 10px;
  width: auto;
  font-size: 16px!important;
}
.dropdown-style {
  width: auto!important;
  border: 0.5px solid #a1b7d0;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 10px;
  width: auto;
  font-size: 16px!important;
}
.dropdown-style input {
  border: 0px solid #a1b7d0!important;
}
.dropdown-style input:focus {
    border: 0px solid blue!important; /* Changes the border color to blue when focused */
}
.dropdown-style-more-feats {
  width: 200px!important;
  border: 0.5px solid #a1b7d0;
  margin: auto;
  margin-right: 0;
  border-radius: 1px;
  text-align: left;
  padding: 4px;
  width: auto;
  font-size: 16px!important;
}
.dropdown-style-more-feats input {
  border: 0px solid #a1b7d0!important;
}
.dropdown-style-more-feats input:focus {
    border: 0px solid blue!important; /* Changes the border color to blue when focused */
}

.special-input-container {
  display: flex;
  align-items: center;
  width: auto;
}

.specialInputNumber {
  border: 0.5px solid #a1b7d0;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 10px;
  width: 87%;
  font-size: 16px!important;
}

.unit-box {
  border: 1px solid #ccc;
  border-left: none; 
  padding: 10px;
  background-color: #f0f0f0;
  font-weight: normal;
  width: 13%;
  font-size: 16px!important;
  text-align: center;
}

.special-unit-box {
  border: 1px solid #ccc;
  border-left: none; 
  padding: 10px;
  background-color: #f0f0f0;
  font-weight: normal;
  width: 45%;
  font-size: 16px!important;
  text-align: center;
}
.sub-panel-title {
  margin-bottom : 4px;
}

.font-weight-normal {
  font-weight: normal!important;
}

.font-weight-500 {
  font-weight: 500!important;
}

.font-weight-600 {
  font-weight: 600!important;
}

.font-spacing {
  letter-spacing: 3px!important;
}

.property-details {
  margin-top: 15px;
}

.unitInputNumber {
  border: 0.5px solid #a1b7d0;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 7px;
  width: 60px;
  font-size: 16px!important;
}

.areaUnitInputNumber {
  border: 0.5px solid #a1b7d0;
  margin: auto;
  border-radius: 1px;
  text-align: left;
  padding: 7px;
  width: 75px;
  font-size: 16px!important;
}
.unitPanel {
  text-align: right;
}
.unitInputLabel {
  text-align: left;
}
.unit-title {
  font-family: 'Ubuntu';
  font-weight: 500;
  font-size: 19px!important;
  letter-spacing: 2px;
}
.page-title {
    letter-spacing: 7px;
    font-weight: lighter;
}
.page-title-mobile {
    letter-spacing: 7px;
    font-weight: lighter;
    font-size: 32px;
    margin-bottom: 20px;
}
.page-link {
    letter-spacing: 4px; /* Adjust the value as needed */
    margin-top: 40px!important;
    margin-bottom: 10px;
    font-weight: lighter;
    font-size: 22px!important;
    color: #006AC3;
    text-decoration: none!important;
    width: 0;
    margin-left: 20px;
    margin-right: 20px;
}
.page-link:hover{
    text-decoration: underline!important;
}

.page-link a{
    text-decoration: none!important;
    bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.title-panel {
  /* display: flex;
  gap: 20px; */
  text-align: justify;
  font-size: 2em;
  border-left: 1px solid #c4c8cc;
  padding-left: 15px;
  /* position: absolute;
  bottom: 0; */
}

.title-panel-mobile {
  /* display: flex;
  gap: 20px; */
  text-align: center;
  font-size: 2em;
  border-top: 1px solid #c4c8cc;
  padding-top: 25px;
  /* padding-left: 15px; */
  /* position: absolute;
  bottom: 0; */
}
.no-bottom-pad{
  padding-bottom: 0px!important;
}
.no-top-pad{
  padding-top : 0px!important;
}
.centered-element {
  padding-top: 10px;
}
</style>
