<!-- Header.vue -->
<template>
  <header>
    <!-- Your header content -->
    <!-- <nav>
      <a href="#">Home</a>
      <a href="#">About</a>
      <a href="#">Contact</a>
    </nav> -->
    <div class="header">
      <v-container>
        <v-row>
          <v-col cols="2" md="4">
            <div class="logo"  v-show="$vuetify.breakpoint.mdAndUp">
              <a href="/">
                <img class="tortue" width="78px" :src="require('@/assets/realogo.png')" alt="">
              </a>
            </div>
            <div class="logo-mobile"  v-show="!$vuetify.breakpoint.mdAndUp">
              <a href="/">
                <img class="tortue" width="78px" :src="require('@/assets/realogo.png')" alt="">
              </a>
            </div>
          </v-col>
          <v-col cols="10" md="8">
            <h1 class="title"><span class="real-estate">Real Estate</span> Alpha <span class="calculator">Calculator</span></h1>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TopHeader'
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700&display=swap');

.header {
    background-color: #006AC3;
    height: 80px;
}
.logo {
    background: white;
    width: 80px;
    height: 95px;
    padding-top: 1px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    margin-left: 150px;
}
.logo-mobile {
    background: white;
    width: 80px;
    height: 95px;
    padding-top: 1px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}
.tortue {
        margin-left: 2px;
}
.title {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    color: white;
    font-size: 24px;
    letter-spacing: 1px;
}
.calculator{
    padding-bottom: 7px;
    font-weight: 300;
    /* border-bottom: 3px solid #E8CF00; */
}
.real-estate{
    padding-bottom: 7px;
    border-bottom: 2px solid #E8CF00;
}
</style>