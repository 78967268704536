<template>
  <div >
    <h1 class="alpha-header">Alpha Results</h1>
    <div >
      <v-container>
        <v-row class="pa-0"  no-gutters>
          <v-col class="pa-0" cols="5" offset="1">
            <div class="pa-0 alpha-panel">
              <div>
                The <b>alpha</b> value for your potential investment is <br/>
                <h1 class="margin-top-ten big-alpha">{{formattedAlpha}} %</h1>
                <div class="investment-indicator">
                  <img :src="selectedImage" :alt="imageAlt" />
                </div>
              </div>
            </div>
          </v-col>
          <v-col class="pa-0" cols="5" >
            <div class="pa-0 results-panel">
              <div class="summary-box">
                <h2>Summary</h2>
                <div class="summary-grid">
                  <div class="summary-item">
                    <span class="label">Area</span>
                    <span class="value">{{ this.neighborhood }}</span>
                  </div>
                  <div class="summary-item">
                    <span class="label">Property type</span>
                    <span class="value">{{ this.alphaResults.property_type }}</span>
                  </div>
                  <div class="result-display">
                  </div>
                  <div class="summary-item">
                    <span class="label">Average rent in the Area</span>
                    <span class="value">$ {{ this.formatNumber(this.alphaResults.avg_rent)}}</span>
                  </div>
                  <div class="summary-item">
                    <span class="label">Average price of properties in the Area</span>
                    <span class="value">$ {{ this.formatNumber(this.alphaResults.avg_property) }}</span> 
                  </div>
                  <div class="summary-item">
                    <span class="label">Expected Returns</span>
                    <span class="value">{{ formattedExpectedReturns }} %</span> 
                  </div>
                  <div class="summary-item">
                    <span class="label">Your Returns</span>
                    <span class="value">{{ formattedActualReturns }} %</span> 
                  </div>
                  <div class="summary-item">
                    <span class="label">Risk Free Rate</span>
                    <span class="value">{{ formattedRFR}} %</span>
                  </div>
                  <div class="summary-item">
                    <span class="label">Beta <BetaTooltip/></span>
                    <span class="value">{{ formattedVolatility }}</span>
                    <!-- <span class="value">{{ this.alphaResults.beta }}%</span> -->
                  </div>
                  <div class="summary-item">
                    <span class="label">Market Sample Size</span>
                    <span class="value">{{ this.alphaResults.market_sample_size.toLocaleString() }} <span style="font-style: normal; font-weight: 400;">Units</span></span>
                  </div>
                  <div v-if="needRec" class="recommendation">
                    <div class="toggle-header" @click="toggleRecommendation">
                      <b>How can you increase your Alpha?</b>
                      <span :class="{ 'arrow-down': isOpenRec, 'arrow-right': !isOpenRec }"></span>
                    </div>
                    <transition name="slide">
                      <div v-if="isOpenRec" class="recommendation-content">
                        <div v-html="recommendation()"></div>
                      </div>
                    </transition>
                  </div>
                  <div v-if="!needRec" class="recommendation">
                    <b class="toggle-header"><div v-html="recommendation()"></div></b>
                  </div>
                  <!-- <router-link to="/" class="page-link" style="width: 100%!important">Back to Calculator</router-link> -->
                  <div class="page-link" style="width: 100%!important" @click="backToForm">Back to Calculator</div>
                </div>
              </div>
<!-- 
              <div class="results-grid">
                <div class="result-box">
                  <h3>Your {{ paymentFrequency.toLowerCase() }} payment</h3>
                  <span class="large-value">${{ calculatePayment().toFixed(2) }}</span>
                </div>
                <div class="result-box">
                  <h3>Total interest paid</h3>
                  <span class="large-value">${{ calculateTotalInterest().toLocaleString() }}</span>
                </div>
              </div> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import BetaTooltip from './BetaTooltip.vue';

export default {
  name: 'ShowAlpha',
  data() {
    return {
      isOpenRec: false
    }
  },
  props: {
    alphaResults: {
      alpha: Number,
      beta: Number,
      avg_rent: Number,
      avg_property_price: Number,
      market_sample_size: Number,
      area: String,
      required: false,
      status: String,
      property_type: String,
      neighborhood: String,
      actual_return: Number,
      expected_return: Number,
      risk_free_rate: Number
    },
    neighborhood: String,
    formData: Object
  },
  components: {
    BetaTooltip
  },
  methods: {
    backToForm(){
      console.log('Back to Form Data:', this.formData);
      this.$router.push({
        name: 'RealEstateAlpha',
        // path: '/',
        params: { initialFormData: this.formData}
      });
    },
    formatNumber(number) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(number);
    },
    need_recommendation() {
      return (this.alphaResults.alpha * 100) < 1.2;
    },
    toggleRecommendation() {
      this.isOpenRec = !this.isOpenRec;
    },
    recommendation() {
      var alpha = this.alphaResults.alpha * 100;
      const area_beta = this.alphaResults.beta > 0.7 ? 'As you are looking at '+ this.alphaResults.property_type + 's right now, you could find an area where their prices are more stable.<br>' : '';
      const returns = this.alphaResults.actual_return < this.alphaResults.risk_free_rate ? 'Your potential returns from this are under the current Risk Free Rate. You could change that by either:<br> (A) Reduce your costs of running your property<br> (B) Find a way to increase the rent.<br> (C) Negotiate the price of the property.' : '';
      const expected_returns = this.alphaResults.actual_return < this.alphaResults.expected_return ? 'Your potential returns from this are under the expected market return. You could change that by either:<br> (A) Reduce your costs of running your property<br> (B) Find a way to increase the rent.<br> (C) Negotiate the price of the property.' : '';
      const hailmary = area_beta + returns + expected_returns === '' ? "Your returns are over the market's and over the risk free rate. The area is not too risky, however the gap between your returns and the market's is not that big. Maybe you should consider review the costs of running this property." : '';

      if (alpha >= 0.8 && alpha <= 1.2) {
        return 'Your alpha is all right but we believe you could do better. Here is how :<br>' + area_beta + returns  + expected_returns + hailmary;
      } else if (alpha > 1.2) {
        return 'It seems like you have great deal on your hands. You should act on it!';
      } else {
        return 'This does not look like the best situation. Here is would you could do to make it worthwhile.<br>' + area_beta + returns  + expected_returns;
      }
    },
  },
  computed: {
    formattedAlpha() {
      const alpha = Number(this.alphaResults.alpha) * 100;
      return isNaN(alpha) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(alpha);
    },
    formattedVolatility() {
      const beta = Number(this.alphaResults.beta);
      // const beta = Number(this.alphaResults.beta) * 100;
      return isNaN(beta) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(beta);
    },
    formattedRFR() {
      const risk_free_rate = Number(this.alphaResults.risk_free_rate) * 100;
      return isNaN(risk_free_rate) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(risk_free_rate);
    },
    formattedActualReturns() {
      const actual_return = Number(this.alphaResults.actual_return) * 100;
      return isNaN(actual_return) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(actual_return);
    },
    formattedExpectedReturns() {
      const expected_return = Number(this.alphaResults.expected_return) * 100;
      return isNaN(expected_return) ? '' : new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 4,
        useGrouping: true
      }).format(expected_return);
    },
    selectedImage() {
      var alpha = this.alphaResults.alpha * 100;
      if (alpha >= 0.8 && alpha <= 1.2) {
        return require('@/assets/mid.png');
      } else if (alpha > 1.2) {
        return require('@/assets/up.png');
      } else {
        return require('@/assets/down.png');
      }
    },
    imageAlt() {
      var alpha = this.alphaResults.alpha * 100;
      if (alpha >= 0.8 && alpha <= 1.2) {
        return 'Neutral investment';
      } else if (alpha > 1.2) {
        return 'Good investment';
      } else {
        return 'Poor investment';
      }
    },
    needRec() {
      return  (this.alphaResults.alpha * 100) < 1.2;
    }
  },
  // created() {
  //   if (!this.$store.state.isAuthenticated) {
  //     this.$router.push('/login');
  // }
// }
    created() {
      console.log('Prop Form Data:', this.formData);
  },
};
</script>

<!--
<style>
.result-display {
  text-align: center;
  margin-top: 20px;
}
</style> -->
<style scoped>
.toggle-header {
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
 text-align: center;
 font-size: 19px;
  /* justify-content: space-between; */
}

.arrow-right::after {
  content: '▶';
  margin-left: 5px;
  transition: transform 0.3s;
}

.arrow-down::after {
  content: '▼';
  margin-left: 5px;
  transition: transform 0.3s;
}

.slide-enter-active, .slide-leave-active {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.recommendation-content {
  margin-top: 10px;
  text-align: justify;
}
.mortgage-results {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3 {
  color: #003366;
}

.summary-box {
  /* background-color: #f0f0f0; */
  /* border-radius: 8px; */
  padding: 20px;
  /* margin-bottom: 20px; */
  border: 1px solid #c4c8cc;
  padding-bottom: 280px;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(3);
  gap: 10px;
}

.summary-item {
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: bold;
  color: #000000;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.result-box {
  background-color: #e6f2ff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}

.large-value {
  font-size: 24px;
  font-weight: bold;
  color: #003366;
}

.chart-section, .amortization-schedule {
  margin-top: 40px;
}
.alpha-panel {
  background-color: #006AC3;
  border: 1px solid #c4c8cc;
  height: 100%;
  /* padding-right: 0px!important; */
  color: #fff;
  display: flex;
  align-items: center; /* Vertically centers the text */
  justify-content: center;
}
.alpha-panel h1{
  color: #fff;
}
.results-pane {
  border: 4px solid #c4c8cc!important;
  /* padding-left: 0px!important; */
}
.margin-top-ten {
  margin-top: 10px;
}
.investment-indicator img {
  margin-top: 10px;
  width: 70px; 
  height: auto;
}
.big-alpha h1 {
  font-size: 37px;
}

.alpha-header {
  margin-top: 60px!important;
  margin-bottom: 40px;
  padding-bottom: 10px;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  border-bottom: 1px solid #c4c8cc;
  letter-spacing: 7px;
  font-weight: lighter;
}
.page-link {
    letter-spacing: 4px; /* Adjust the value as needed */
    margin-top: 40px!important;
    margin-bottom: 10px;
    font-weight: lighter;
    font-size: 22px!important;
    color: #006AC3;
    text-decoration: none!important;
}
.page-link:hover{
    text-decoration: underline!important;
}
.recommendation {
  margin-left: 12%;
  margin-right: 12%;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #c4c8cc;
}
</style>
